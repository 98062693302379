.header {
    z-index: 1;
    font-family: "SF Mono";
    width: 100%;
    background-color: #00000000;
    color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    font-size: calc(10px + 2vmin);
    align-items: center;
    overflow-x: hidden;
    
}

.expBar {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    font-size: small;
}

.bar {
    position: relative;
    width: 100%;
    height: 10%;
    color: #000000;
    background: linear-gradient(90deg, #5A446B 0%, #8F00FF 0.01%, #FFA800 100%);
    border-radius: 2px;
}

.barContainer {
    width: 100%;
    height: 20%;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-4470px);
    }
}

.techSkills {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    height: 100lvh;
}

.scroller {
    display: inline-block;
    animation: scroll 45s linear infinite;
    margin-top: 10vh;
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 100%;
    position: relative;
}

.langRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* font-size: 24px; */
    justify-items: right;
    align-items: center;
    color: #ffffff9c;
    transition: color 0.3s ease;
    cursor: pointer;
    box-sizing: border-box;
}

.langRow:hover {

    color: #FFFFFF;

}

.langPadding {
    padding: 10px;
    box-sizing: border-box;
}

.subs {
    width: 100%;
    height: 100%;
    font-size: small;
    text-align: left;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
}

.praContainer {
    text-align: left;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 2vh;
}

.praMain {
    width: 90%;
    border-color: #FFFFFF;
    border-width: 3px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.08);

    border: 2px solid white;
    font-size: xx-large;
    position: relative;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    
}



.praMain2 {
    border-color: #FFFFFF;
    border-width: 3px;
    border-radius: 10px;

    background-color: rgba(255, 255, 255, 0.08);
    border: 2px solid white;
    box-sizing: border-box;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    margin-top: 5svh;
    width: 90%;
    padding: 10px;
    font-size: 80%;
    font-weight: bold;

    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.praTitle {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    font-size: 85%;
    box-sizing: border-box;
}
@media (max-width: 800px) {
    .praTitle {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 2%;
        padding-bottom: 2%;
        width: 100%;
        font-size: 65%;
        box-sizing: border-box;
    }
}

.infoBit {
    font-size: 40%;
    padding: 5%;
}

.eduContainer {
    display: flex;
    justify-content: center;
    padding: 2vh;
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 20vh;
    box-sizing: border-box;
}

.ampersand{
    position:fixed;
    z-index: -10;
}

.bounds{
    box-sizing: border-box;
}

.buttonContainer {
    position: relative;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
}

.highlighter {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    opacity: 0.75;
    transition: width 0.3s ease;
    z-index: -1;
}

.buttonContainer:hover .highlighter {
    width: 100%;
}

.buttonText {
    color: rgba(255, 255, 255, 0.702);
    transition: color 0.3s ease;
}

.buttonText:hover{
    color: rgb(255, 255, 255);
}

.observe{
    opacity: 1;
    transition: opacity 0.7s ease-out;
}

.obsr2{
    
    opacity: 0;
    transition: opacity 0.7s ease-out;
    
}

.downloadContainer{
    position: relative;
    display: flex;
    justify-content: center;
    padding: 2vh;
    width: 70%;
    margin-top: 40vh;
    margin-bottom: 20vh;
    box-sizing: border-box;
    cursor: pointer;
    min-height: 10vh;
    transition: width 0.3s ease;
}
.downloadContainer:hover{
    width: 100%;
}

.resumeRotation2{
    transform-origin: 50% 50%;
}
.resumeRotation{
    animation: rotation 60s infinite linear;
    transform-origin: 50% 50%;
}

.bounce{
    animation: bounce 2.5s infinite;
}

.langCTR{
    display: grid; 
    width: 100%; 
    padding: 5svh;
    font-size: 24px;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
}
@media (max-width: 800px) {
    .langCTR {
        display: grid; 
        font-size: 12px;
        padding: 1svh;

        width: 100%; 
        box-sizing: border-box;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: center;
    }
}

.downloadSuperContainer{
    display: flex;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100%;
}
@media (max-width: 550px) {
    .downloadSuperContainer {
        display: flex;
        align-self: center;
        justify-self: center;
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 100%;
    }
}
.downloadHyperContainer{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 100px;
    width: 100%;
    height: 100svh;
    color: white;
    transition: color 0.3s ease;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}