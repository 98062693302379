.main{
    background-color: #171717;
    position: fixed;
    justify-content: center;
    display: grid;
    min-height: 4vh;
    min-width: 100%;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 0px;
    justify-content: center;
    z-index: 3;
}

.txt{
    font-family: "SF Mono";
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    font-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 1vh;
    margin-bottom: 1vh;
    transition: color 0.3s ease-in-out;
    color: #514d4d;
}

.txt:hover{
    transition: color 0.3s ease-in-out;
    color: #b4b4b4;
}