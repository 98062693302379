.header {
    z-index: 1;
    font-family: "SF Mono";
    width: 100%;
    height: 100%;
    background-color: #00000000;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin-top: 2vh;
}

.title {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: xx-large;
    padding-bottom: 4vh;
    box-sizing: border-box;
    font-weight: 500;
}

.contactFormContainer {
    width: 80%;
    display: grid;
    text-align: left;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
    grid-auto-rows: 1fr;
    column-gap: 30px;
    row-gap: 30px;
    box-sizing: border-box;
}

.contactFormSubs {
    width: 100%;
    display: grid;
    text-align: left;
    justify-content: center;
    grid-template-columns: 100%;
    grid-auto-rows: 1fr;
    column-gap: 30px;
    row-gap: 30px;
    box-sizing: border-box;
}

.textStyles {
    font-family: "SF Mono";
    font-size: large;
    color: white;
    background-color: rgba(0, 0, 0, 0.25);
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 3%;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
}

.messageContainer {
    font-family: "SF Mono";
    font-size: medium;
    color: white;
    background-color: rgba(0, 0, 0, 0.25);
    border: 1px solid white;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
}

.message {
    font-family: "SF Mono";
    font-size: medium;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    padding: 3%;
    border: none;
    display: block;
    width: 100%;
    height: 100%;
    overflow: auto;
    line-height: 1.5;
    resize: none;
    box-sizing: border-box;
    outline: none;
}

.submit {
    font-family: "SF Mono";
    font-size: large;
    color: rgb(255, 255, 255);
    background-color: rgba(52, 52, 52, 0.25);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    width: 50%;
    height: 100%;
    transition: background-color 0.2s ease-in-out;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
}

.submit:hover {

    cursor: pointer;
    background-color: rgba(114, 114, 114, 0.5);

}

.submit:active {

    background-color: rgba(41, 41, 41, 0.5);
}

.submit:disabled {

    color:#878787;
    background-color: rgba(69, 69, 69, 0.5);
    cursor: not-allowed;

}

.socialsContainer {
    width: 95%;
    height: 80px;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
    justify-content: center;
    box-sizing: border-box;
    font-size: large;
}

.socialsContainerFlex {
    width: 90%;
    display: flex;
    text-align: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    font-size: calc(5px + 1.5vw);
    margin-top: 4vh;
}

.indivSocial{
    width: fit-content;
    display:flex;
    justify-content: center;
    padding: 2.5%;
    padding-bottom: 0px;
}

.transparent{
    opacity: 0;
    transition: opacity 1s ease-in-out;
}