.header {

    z-index: 1;
    font-family: "SF Mono";
    width: 100%;
    background-color: #0e0e0e;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);

}

.spotlightTitle {
    
    display: flex;
    width: 85%;
    height: 150px;
    justify-content: center;
    align-content: center;
    padding: 50px;
    margin-top: 50px;

}

.otherTitle {

    display: flex;
    width: 78.5%;
    justify-content: center;
    height: 150px;
    align-content: center;
    margin-top: 50px;
    padding: 50px;

}

.cardSpotWrapper {

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(calc(350px + 10vmin), 100%), 1fr));
    column-gap: 40px;
    row-gap: 40px;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 4vh;

}
.likeButtonCtr{
    display: flex;
    /* min-width: 20%; */
    flex-direction: row;
    height: 100%;
    transition: color 0.2s ease;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
}
.likeButton{
    height: 19px;
    width: 20px;
    transition: color 0.2s ease;
    cursor: pointer;
    padding-right: 2px;
    box-sizing: border-box;
}
.cardWrapper {

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(calc(250px + 10vmin), 100%), 1fr));
    column-gap: 40px;
    row-gap: 40px;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 4vh;

}

.cardMain:hover {

    border: none;
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

}

.cardSpotMain:hover {

    border: none;
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

}

.cardPadding {

    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

}

.cardSpotPadding {
    column-span: all;
    width: 75%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
}

.cardSpotMain {

    display: grid;
    background-color: #5b5b5b6b;
    color: white;
    max-height: fit-content;
    width: 85%;
    height: 100%;
    border-radius: 10px;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.spotInfo {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    color: #FFFFFF;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.cardMain {

    display: grid;
    background-color: #5b5b5b6b;
    color: white;
    max-height: fit-content;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

.mediaContainer {

    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 10px 10px 0px 0px;
}

.media {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
    top: 0;
    left: 0;

}

.mainWidth {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.flatAsterisk {
    position: fixed;
    z-index: -7;
    top: -15vh;
    left: -70vh;
    overflow: hidden;
    animation: spin 120s linear infinite;
}

.cardText {
    font-size: calc(10px + 1vmin);
    display: flex;
    width: 100%;
    min-height: calc(10px + 1vmin);
    box-sizing: border-box;
    flex-direction: rows;
}

.cardSpotText {
    font-size: calc(10px + 1.2vw);
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: rows;
}

.observe{
    /* filter: blur(0);
    transition: filter 1s ease-out; */
    opacity: 1;
    transition: opacity 1s ease-out
}