.header {
    z-index: 1;
    font-family: "SF Mono";
    background-color: #00000000;
    /* min-height: 100vh; */
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.txt {
    font-size: calc(5px + 12vw);
    text-align: left;
    height: 100vh;
    margin: 0;
    padding-left: 4vw;
    padding-top: 4vh;
    display: flex;
    align-items: center;
    position: relative;
}

.projLink {
    text-decoration: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.189);
    transition: opacity 0.3s ease-in-out;
    opacity: 0.8;
    width: fit-content;
}

.projLink:hover {

    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

@keyframes consume {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.projLink2 {
    text-decoration: none;
    color: rgb(205, 205, 205);
    width: fit-content;
    height: fit-content;
}

.projLink3 {
    position: absolute;
    width: 100%;
    height: 100%;

}

.projLink3:hover {
    background: linear-gradient(90deg, #ffffff30 100%, #00000000 0%);
    animation: consume 0.3s linear;

}


.aboutContainer {
    text-align: right;
    height: 100vh;
    padding-top: 4vh;
    max-height: 100svh;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.bwImage {
    filter: grayscale(50%);
    transition: filter 0.3s ease; /* Adds a smooth transition effect */
}

.aboutTxt {
    font-size: calc(5px + 7vw);
    font-weight: bold;
}

.aboutSubTxt {
    font-size: calc(5px + 1.5vw);
    justify-self: end;
    align-items: start;
    margin-block-start: 0em;
    margin-block-end: 0em;
    width: 40vw;
}

.mainGrid {
    width: 100%;
    max-height: max-content;
}

.adjs {
    font-size: calc(5px + 1.3vw);
    font-weight: bold;
    width: 55vw;
    text-align: right;
    height: 150vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

}

.directory {

    height: 92.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

}

.tmt {
    text-align: center;
    font-size: calc(5px + 2vw);
}

.tmtContent {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    text-align: center;
    font-size: calc(5px + 2vw);
}

.gridContainer {
    width: 100%;
    height: 100%;
}

.highlight {
    background-color: #965eff2b;
    width: fit-content;
    height: fit-content;
}

.buttonContainer {
    position: relative;
    display: inline-block;
}

.highlighter {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.275);
    transition: width 0.3s ease;
    z-index: -1;
}

.buttonContainer:hover .highlighter {
    width: 100%;
}

.buttonText {
    color: rgb(174, 174, 174);
    transition: color 0.3s ease;
}

.buttonText:hover{
    color: rgb(255, 255, 255);
}

.observe{
    opacity: 1;
    transition: opacity 1s ease-out;
}
